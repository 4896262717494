import { Component } from '@angular/core';
import { ContentService } from 'src/app/service/content.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent {
  errorMsg: any;
  infoMsg: any;
  data: any = {};
  pageData: any = {};

  constructor(public contentService:ContentService,private router: Router) { }

  ngOnInit():void {
    this.contentService.getTerms(this.data).subscribe((result: any)=>{
      if (result.errors.length > 0)
        this.errorMsg = result.errors[0];
      else {
        this.pageData = result.data.page;
      }
    });
  }

}
