<div id="contact" class="form-1">
    <div class="container">
         <!-- end of row -->
        <div class="row">
            <div class="col-lg-6 offset-lg-3 text-start">
                <h2>Sign Up</h2>
                <p class="secondary-color">Sign in to see your current projects, approved content and more!</p>
                <!-- Contact Form -->
                <form [formGroup]="registrationForm" (ngSubmit)="signupUser()">
                    <div class="form-group">
                        <input type="text" class="form-control-input" placeholder="Name" formControlName="name">
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control-input" placeholder="Email" formControlName="email">
                    </div>

                    <div class="form-group">
                        <textarea class="form-control-textarea" placeholder="Address" formControlName="address"></textarea>
                    </div>
                    <div class="form-group">
                        <input type="date" class="form-control-input" placeholder="Date of Birth" formControlName="dob">
                    </div>
                    <div class="form-group"> 
                        <select class="form-control-select" formControlName="gender" placeholder="Gender">
                            <option class="select-option" value="">Select</option>
                            <option class="select-option" value="Male">Male</option>
                            <option class="select-option" value="Female">Female</option>
                            <option class="select-option" value="Other">Other</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control-input" placeholder="Instagram ID" formControlName="insta">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control-input" placeholder="Tiktok ID" formControlName="tiktok">
                    </div>
                    <div class="form-group">
                        <label class="custom-checkbox">
                            <div >
                                <div *ngFor="let item of genreData">
                                    <input type="checkbox" (change)="getGenreId($event,item.id)" name="{{ item.id }}" value="{{ item.id }}" name="checkbox-checked" />
                                    {{ item.name}} 
                                </div>                                
                            </div>
                        </label>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control-input" placeholder="Password" formControlName="password">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control-input" placeholder="Confirm Password" formControlName="cnfirmpassword">
                    </div>
                  <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                    <div class="row">
                       <div class="col-md-6">
                            <div class="form-group">
                                <button  [disabled]="registrationForm.invalid" type="submit" class="form-control-submit-button">Submit</button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="text-end mt-2">
                                <a href="javascript:void(0)">Forgot Your Password?</a>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- end of contact form -->
                <p class="text-center mt-5">Already have an account? <a routerLink="/login">Sing In!</a></p>
                <p class="text-center">By signing up, you are agree with our <a routerLink="/terms">Terms &amp; Conditions</a></p>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>
