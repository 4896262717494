import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  genreList() {
    
    let data: any = {}
    //data.generic_status = campaignType;
   // let headers = new HttpHeaders()
   //   .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'get-genres', data).pipe(
      //catchError(this.errorHandle)
    );
  }
}
