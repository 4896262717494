import { Component, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
    // Variable to store shortLink from api response
    shortLink: string = "";
    loading: boolean = false; // Flag variable
    file: any = null; // Variable to store file
    private imageSrc: string = '';
    campaignId: string | null = null;
    stat_file: any = null;
    data:any={};
    errorMsg: any;
    infoMsg: any;

    constructor(private fileUploadService: FileUploadService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.campaignId = this.route.snapshot.paramMap.get("campaignid");

    }


    handleInputChange(e: any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        //var pattern = /application/pdf/;
        var reader = new FileReader();
        ///if (!file.type.match(pattern)) {
        //    alert('invalid format');
        //    return;
        //}
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }
    _handleReaderLoaded(e: any) {
        let reader = e.target;
        this.imageSrc = reader.result;
        this.stat_file = this.imageSrc;
        // console.log(this.imageSrc)
    }

    onUpload() {
        this.data.campaign_id = this.campaignId;
        this.data.stat_name = 'stat_2';
        this.data.stat_file = this.stat_file;

       // console.log("PPPPP:",this.data);
        //return false;
        this.fileUploadService.upload(this.data).subscribe((result: any) => {
            //console.log("PPPPP:",result);
            if (result.status !== 200) {
                this.errorMsg = result.data.message;
            } else {
                this.infoMsg = result.data.message;
                //this.showThis = false;
                //card_btns
                //this.router.navigate(['/campaign/2']);

            }
            /*if (result.status !== 200) {
                this.errorMsg = result.data.message;
                this.router.navigate(['/campaign/4']);
            } else {
                this.infoMsg = result.data.message;
                this.showThis = false;
                //card_btns
                this.router.navigate(['/campaign/2']);

            }*/
        });
        /*this.loading = !this.loading;
        //console.log(this.file);
        this.fileUploadService.upload(this.file).subscribe(
            (event: any) => {
                if (typeof (event) === 'object') {

                    // Short link via api response
                    this.shortLink = event.link;

                    this.loading = false; // Flag variable 
                }
            }
        );*/
    }

}
