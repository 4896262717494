<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                <h2 class="mb-5">Change password</h2>
                <div class="sponsored">
                    <div class="card mb-3 col-lg-12" style="max-width:100%">
                        <div class="col-lg-6 offset-lg-3 text-start mt-3">
                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control-input" placeholder="Old Password" required="">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control-input" placeholder="New Password" required="">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control-input" placeholder="Confirm Password"
                                    required="">
                            </div>
                            <div class="form-group">
                                <button type="submit" class="form-control-submit-button">Save</button>
                            </div>
                        </form>
                    </div>
                    </div>



                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>