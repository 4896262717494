import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private http: HttpClient
  ) { }

  chatList(data:any) {

    console.warn("data:",data);

    data.chat_type = 'CAMPAIGN';
    data.chat_type_id = data.campaignId;
    data.admin_id = data.adminId;
    data.offset = 0;
    data.limit = 100;

    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'chat/read', data, { headers }).pipe(
      //catchError(this.errorHandle)
    );

    /*this.activeRoute.paramMap.subscribe(res => {
       this.pageId = res.get("campaignType");
      // console.log("res:",res.get("campaignType"));
    });
    console.warn("campaignType:",this.pageId);*/
    /*let data: any = {}
    data.generic_status = campaignType;
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'campaign/list-by-generic-status', data, { headers }).pipe(
      //catchError(this.errorHandle)
    );*/
  }

  addChat(data:any) {

    console.warn("data:",data);

    data.chat_type = 'CAMPAIGN';
    data.chat_type_id = data.campaignId;
    data.admin_id = data.adminId;
    data.message = data.message;
    data.file_data = '';

    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'chat/write', data, { headers }).pipe(
      //catchError(this.errorHandle)
    );

    /*this.activeRoute.paramMap.subscribe(res => {
       this.pageId = res.get("campaignType");
      // console.log("res:",res.get("campaignType"));
    });
    console.warn("campaignType:",this.pageId);*/
    /*let data: any = {}
    data.generic_status = campaignType;
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'campaign/list-by-generic-status', data, { headers }).pipe(
      //catchError(this.errorHandle)
    );*/
  }
}
