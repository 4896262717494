<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                <h2>Organization Invites</h2>
                <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                <div class="sponsored">
                    <div class="card mb-3"  *ngFor="let item of organizationList | paginate:{
                        itemsPerPage:tableSize,
                        currentPage:page,
                        totalItems:count
                    }; let i=index">
                        <div class="row no-gutters cursor-pointer" >
                            <div class="col-md-4 list_thumbnail">
                                <img src={{item.organizationInfo.logo}} class="card-img" alt="...">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body" >
                                    <h4 class="card-title">{{item.organizationInfo.name}}</h4>
                                    <h5>{{item.organizationInfo.invitation_date}}</h5>
                                    <p class="card-text">{{ item.organizationInfo.description }}</p>
                                    <h6>Date: <span>{{ item.invitation_date }} </span></h6>
                                    <div class="card_btns" *ngIf="item.invitation_status=='INVITED'">
                                        <!--<a routerLink="/organization/details/{{item.organizationInfo.id}}" class="btn btn-outline-info">Details</a> &nbsp;-->
                                        <a href="javascript:void(0)" class="btn btn-outline-success"
                                            (click)="clickConfirm(item.organizationInfo.id)">Accept</a> &nbsp;
                                        <a href="javascript:void(0)" class="btn btn-outline-danger"
                                            (click)="clickCancel(item.organizationInfo.id)">Decline</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div class="pagination">
                      <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)"></pagination-controls>
                    </div>-->
                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>
