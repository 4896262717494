import { Component } from '@angular/core';
import { ContentService } from 'src/app/service/content.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  pagedate:any;
  constructor(private content:ContentService) { }

  ngOnInit(): void {
    let page='about-us';
     this.content.contentList(page).subscribe((result: any) => {
      this.pagedate=result.data;
     });
  }
}
