import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';
import { EventService } from 'src/app/service/event.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent {
  //organizationList: any;
  errorMsg: any;
  infoMsg: any;
  showThis: any = true;
  eventId: string | null = null;

  title = "pagination";
  page: number = 1;
  count: number = 0;
  tableSize: number = 4;
  tableSizes: any = [5, 10, 15, 20];

  //Define a property to store data
  eventList: any;
  //public errorMsg: any;
  isError: boolean = false;
  pageId: any;
  cType: any;
  data: any = {};

  constructor(
    private event: EventService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private eventService: EventService,
    private router: Router
  ) { }

  giftType: string | null = null;

  ngOnInit(): void {
    this.eventDataList();
  }

  eventDataList(): void {
    this.activatedRoute.paramMap.subscribe(res => {
      this.pageId = res.get("eventType");
      if (this.pageId == '1') {
        this.cType = 'ACTIVE';
      }
      else if (this.pageId == '2') {
        this.cType = 'UPCOMING';
      }
      else if (this.pageId == '3') {
        this.cType = 'COMPLETED';
      }
      else {
        this.cType = 'INVITED';
      }


      this.event.eventList(this.cType).subscribe((result: any) => {
        this.eventList = result.data.events;
        console.warn("AAAA:", result.data.gifts);
        if (result.status === 200) {
          this.errorMsg = result.data.message;
        } else {
          this.errorMsg = result.data.message;
        }
      });
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.eventDataList();
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.eventDataList();
  }


  clickConfirm(giftId: any) {
    if (confirm("Are you sure to accept this event invite?")) {


      //alert(giftId);
      this.data.event_id = giftId;
      this.data.is_accepted = true;

      this.eventService.acceptEvent(this.data).subscribe((result: any) => {
        if (result.status !== 200) {
          //console.warn("AAAA:",result.errors);
          this.errorMsg = result.errors;
          this.router.navigate(['/event/4']);
        } else {
          this.infoMsg = result.data.message;
          this.showThis = false;
          //card_btns
          this.router.navigate(['/event/2']);

        }
      });
    }
  }

  clickCancel(eventId: any) {
    if (confirm("Are you sure to cancel this event invite?")) {
      this.data.event_id = eventId;
      this.data.is_accepted = false;
      this.data.reason_for_decline = 'I already have too much work.';
      this.data.decline_message = "I already have too much work.";

      this.eventService.declineEvent(this.data).subscribe((result: any) => {
        if (result.status !== 200) {
          this.errorMsg = result.errors;
          this.showThis = true;
          this.router.navigate(['/event/1']);
        } else {
          this.infoMsg = result.data.message;
          this.showThis = false;
          this.router.navigate(['/event/1']);
        }
      });
    }
  }
}
