import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  errorMsg: any;
  infoMsg: any;
  data: any = {};
  registrationEmail: any;

  theForm: any = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    otp: new FormControl('', [Validators.required, Validators.minLength(4)]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    password_confirmation: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  constructor(private router: Router, public userService:UserService) { }

  ngOnInit():void{
    // REDIRECT MSG
    if(localStorage.getItem('redirectMsg'))
    {
      this.infoMsg = localStorage.getItem('redirectMsg');
      localStorage.removeItem('redirectMsg');
    }

    this.registrationEmail = localStorage.getItem('registrationEmail');
  }

  confirmOtp():void{
    this.data.email = this.theForm.value.email;
    this.data.otp = this.theForm.value.otp;
    this.data.password = this.theForm.value.password;
    this.data.password_confirmation = this.theForm.value.password_confirmation;

    this.userService.resetPasswordVerifyOtp(this.data).subscribe((result: any)=> {
      if (result.errors.length > 0)
        this.errorMsg = result.errors[0];
      else {
        localStorage.setItem('redirectMsg', 'Your password has been set, login to continue');
        this.router.navigate(['/login']);
      }
    });

  }
}
