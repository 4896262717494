<div id="contact" class="form-1">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-6 offset-lg-3 text-start">
                <h2>Sign In</h2>
              <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                <p class="secondary-color">Sign in to see your current projects, approved content and more!</p>
                <!-- Contact Form -->
                <form [formGroup]="loginForm" (ngSubmit)="loginUser()">

                    <div class="form-group">
                        <input type="email" class="form-control-input" placeholder="Username" formControlName="email">
                        <span class="form-text text-danger" *ngIf="email && email.invalid && email.touched">This field
                            is required</span>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control-input" placeholder="Password"
                            formControlName="password">
                        <span class="form-text text-danger"
                            *ngIf="password && password.invalid && password.touched">This field is required</span>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <button [disabled]="loginForm.invalid" type="submit"
                                    class="form-control-submit-button">Submit</button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="text-end mt-2">
                                <a routerLink="/forgot-password">Forgot Your Password?</a>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- end of contact form -->
                <p class="text-center mt-5">Don’t have an account? <a routerLink="/registration">Create new now!</a></p>
                <p class="text-center">By signing up, you are agree with our <a routerLink="/terms">Terms</a> and
                  <a routerLink="/privacy">privacy policy</a></p>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>
