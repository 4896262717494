import { Component } from '@angular/core';
declare var window:any;
@Component({
  selector: 'app-accept-modal',
  templateUrl: './accept-modal.component.html',
  styleUrls: ['./accept-modal.component.css']
})
export class AcceptModalComponent {
  formModal:any;

  ngOnInit():void{
    this.formModal=new window.bootstrap.Modal(
      document.getElementById("acceptModal")
    );
  }
  
  openAcceptModal(){
    this.formModal.show();
  }
  
  closeAcceptModal(){
    this.formModal.hide();
  }
}
