import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from 'src/app/service/organization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.css']
})
export class OrganizationDetailsComponent {
  details: any;
  sendArr: any = {};
  successMsg: any;
  organizationId: string | null = null;
  data: any = {};
  errorMsg: any;
  infoMsg: any;
  constructor(private organizationService: OrganizationService, private route: ActivatedRoute,private router:Router) { }

  ngOnInit(): void {

    this.organizationId = this.route.snapshot.paramMap.get("id");
    //this.data.id=this.details;
    //console.warn("org1:",this.organizationId);
    this.organizationService.organizationDetails(this.organizationId).subscribe((result: any) => {
      //this.campaignList = this.sanitizer.bypassSecurityTrustHtml(result.data.campaigns);  
      
      this.details = result.data.organizationInfo;
      if(result.status==200 && this.details===null)
      {
        this.successMsg = 'No data available';
      }
      //console.warn("orgde:", result.status);
    })
  }

  /*acceptOrganization(organizationId: number): void {
    this.sendArr.organization_id = organizationId,
      this.sendArr.is_accepted = true,
      //this.sendArr.reason_for_decline='2',
      //this.sendArr.decline_message='2',

      this.organizationService.organizationAction(this.sendArr).subscribe((result: any) => {
        if (result.status !== 200) {
          this.errorMsg = result.errors;
          this.router.navigate(['/organization/details/'+organizationId]);
        } else {
          this.infoMsg = result.data.message;
          //this.showThis=false;
          //card_btns
          this.router.navigate(['/organization/details/'+organizationId]);
         
        }
      });


    // console.warn("acceptOrganization",organizationId);
  }*/
}
