<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">

            <div class="col-lg-12">
                <h2>Sponsored Events </h2>
                <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                <div class="sponsored">
                    <div class="card mb-3" *ngFor="let item of eventList">
                        <div class="row no-gutters">
                            <div class="col-md-4 list_thumbnail">
                                <img src={{item.eventInfo.images[0].image_name}} class="card-img" alt="...">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h4 class="card-title">{{item.eventInfo.name}}</h4>
                                    <h5>Start On: {{item.eventInfo.start_date | date:'longDate'}} </h5>
                                    <div class="card-text" [innerHTML]="item.eventInfo.description"></div>
                                    <div class="card_btns" *ngIf="item.status=='INVITED'">
                                        <a href="javascript:void(0)" class="btn btn-outline-success"
                                            (click)="clickConfirm(item.eventInfo.id)">Accept</a> &nbsp;
                                        <a href="javascript:void(0)" class="btn btn-outline-danger"
                                            (click)="clickCancel(item.eventInfo.id)">Decline</a>
                                    </div>
                                    <div class="card_btns" *ngIf="(item.status==='REJECTED')">
                                        <a href="javascript:void(0)" class="btn btn-danger btn-lg">REJECTED</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>