import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from 'src/app/service/user.service';
import { RegistrationService } from 'src/app/service/registration.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  registrationEmail: any;
  errorMsg: any;
  infoMsg: any;
  data: any = {};
  myself: any = {};
  private imageSrc: string = '';
  genreData: any;
  selectedGenre: any = [];
  formValue:any={};

  theForm: any = new FormGroup({
    full_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    address: new FormControl(''),
    gender: new FormControl(''),
    insta_id: new FormControl(''),
    tiktok_id: new FormControl(''),
    date_of_birth: new FormControl(''),
    mobile: new FormControl(''),
    password: new FormControl(''),
    password_confirmation: new FormControl(''),
    profile_pic: new FormControl(''),
    genre_ids : new FormControl(''),
  });
  
  constructor(private router: Router, public userService: UserService, public registration:RegistrationService) { }

  ngOnInit(): void {
    // REDIRECT MSG
    if (localStorage.getItem('redirectMsg')) {
      this.infoMsg = localStorage.getItem('redirectMsg');
      localStorage.removeItem('redirectMsg');
    }

    this.userService.getLoginUser(this.data).subscribe((result: any) => {
      console.warn("result:",result.data.userInfo);
      if (result.errors.length > 0)
        this.errorMsg = result.errors[0];
      else {
        this.myself = result.data.userInfo;
      }
    });
    
    this.genreList();
    this.selectedGenre = new Array<string>();

  }

  updateProfile(): void {
    this.errorMsg = '';
    this.infoMsg = '';

    this.data.full_name = this.theForm.value.full_name;
    this.data.email = this.theForm.value.email;
    this.data.address = this.theForm.value.address;
    this.data.gender = this.theForm.value.gender;
    this.data.insta_id = this.theForm.value.insta_id;
    this.data.tiktok_id = this.theForm.value.tiktok_id;
    this.data.date_of_birth = this.theForm.value.date_of_birth;
    this.data.mobile = this.theForm.value.mobile;
    this.data.password = this.theForm.value.password;
    this.data.password_confirmation = this.theForm.value.password_confirmation;
    this.data.profile_pic = this.imageSrc;
    this.data.genre_ids = this.selectedGenre;

    console.warn("Profile:", this.data);
    this.userService.profileUpdate(this.data).subscribe((result: any) => {
      if (result.errors.length > 0)
        this.errorMsg = result.errors[0];
      else
        this.infoMsg = result.data.message;
    });
  }

  handleInputChange(e: any) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e: any) {
    let reader = e.target;
    this.imageSrc = reader.result;
    //this.stat_file = this.imageSrc;
    //console.log("kk",this.imageSrc)
  }

  genreList(): void {
      this.registration.genreList().subscribe((result: any) => {
        this.genreData= result.data.list;
      });
  }

  getGenreId(e: any, id: any) {
    //this.selectedGenre.id= e.target.checked
    //const emailFormArray = <FormArray>this.myForm.controls.useremail;
    if (e.target.checked) {
      this.selectedGenre.push(id);
    }
    else {
      this.selectedGenre = this.selectedGenre.filter((m: any) => m != id)
    }
  }

}
