import { Component,OnInit } from '@angular/core';


declare var window:any;
@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.css']
})
export class ModalPopupComponent implements OnInit {
  formModal:any;

ngOnInit():void{
  this.formModal=new window.bootstrap.Modal(
    document.getElementById("exampleModal")
  );
}

openModal(){
  this.formModal.show();
}

closeModal(){
  this.formModal.hide();
}
}
