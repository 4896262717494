import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/service/registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {
  errorMsg: any;
  data: any = {};
  genreData: any;
  selectedGenre:any=[];

  ngOnInit(): void {
    this.genreList();
    this.selectedGenre=new Array<string>();
  }

  genreList(): void {
      this.registration.genreList().subscribe((result: any) => {
        this.genreData= result.data.list;
      });
  }
  registrationForm: any = new FormGroup({
    /*name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    cnfirmpassword: new FormControl(''),
    address: new FormControl('', [Validators.required]),
    dob: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    insta: new FormControl('', [Validators.required]),
    tiktok: new FormControl('', [Validators.required]),*/

    name: new FormControl('', []),
    email: new FormControl('', []),
    password: new FormControl('', []),
    cnfirmpassword: new FormControl(''),
    address: new FormControl('', []),
    dob: new FormControl('', []),
    gender: new FormControl('', []),
    insta: new FormControl('', []),
    tiktok: new FormControl('', []),
  });

  constructor(public userService:UserService,private router: Router, public registration:RegistrationService) { }
  signupUser(){
    this.data.full_name = this.registrationForm.value.name;
    this.data.email = this.registrationForm.value.email;
    this.data.password = this.registrationForm.value.password;
    this.data.address = this.registrationForm.value.address;
    this.data.date_of_birth = this.registrationForm.value.dob;
    this.data.gender = this.registrationForm.value.gender;
    this.data.insta_id = this.registrationForm.value.insta;
    this.data.tiktok_id = this.registrationForm.value.tiktok;
    this.data.genre_ids = this.selectedGenre;

    this.userService.addUser(this.data).subscribe((result: any)=>{
      if (result.errors.length > 0)
        this.errorMsg = result.errors[0];
      else {
        localStorage.setItem('registrationEmail', this.data.email);
        this.userService.getOtp(this.data).subscribe((result: any) => {
          if (result.errors.length > 0)
            this.errorMsg = result.errors[0];
          else {
            localStorage.setItem('redirectMsg', 'OTP sent to your email, please verify');
            this.router.navigate(['/verify-otp']);
          }
        });
      }
    });

  }
  get name() {
    return this.registrationForm.get('name');
  }
  get email() {
    return this.registrationForm.get('email');
  }
  get password() {
    return this.registrationForm.get('password');
  }
  get cnfirmpassword() {
    return this.registrationForm.get('cnfirmpassword');
  }
  get address() {
    return this.registrationForm.get('address');
  }
  get dob() {
    return this.registrationForm.get('dob');
  }
  get gender() {
    return this.registrationForm.get('gender');
  }
  get insta() {
    return this.registrationForm.get('insta');
  }
  get tiktok() {
    return this.registrationForm.get('tiktok');
  }

  getGenreId(e:any,id:any){
    if(e.target.checked)
    {
      this.selectedGenre.push(id);
    }
    else {
      this.selectedGenre=this.selectedGenre.filter((m:any)=>m!=id)
    }
  }
}
