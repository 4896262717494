import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/service/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent {
  adminId: any;
  campaignId: any;
  data: any = {};
  chatList: any;
  inputMsg:string='';
  errorMsg: any;
  infoMsg: any;

  constructor(
    private chat: ChatService,
    // private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    //private commonService:CommonService,
    //private campaignService:CampaignService,
     private router:Router
  ) { }

  ngOnInit(): void {
    this.chatDataList();
  }
  chatDataList(): void {
    this.activatedRoute.paramMap.subscribe(res => {
      this.adminId = res.get("adminId");
      this.campaignId = res.get("campaignId");

      this.data.adminId = this.adminId;
      this.data.campaignId = this.campaignId;
      // alert(this.data);
      /*if (this.pageId == '1') {
        this.cType = 'ACTIVE';
      }
      else if (this.pageId == '2') {
        this.cType = 'UPCOMING';
      }
      else if (this.pageId == '3') {
        this.cType = 'COMPLETED';
      }
      else {
        this.cType = 'INVITED';
      }*/


      this.chat.chatList(this.data).subscribe((result: any) => {
        console.log("ChatData:", result.data);
        this.chatList = result.data.conversations;
        //this.campaignList = this.sanitizer.bypassSecurityTrustHtml(result.data.campaigns);  
        /* this.campaignList = result.data.campaigns;
         console.warn("AAAA:", result.data.campaigns);
         if (result.status === 200) {
           this.errorMsg = result.data.message;
         } else {
           this.errorMsg = result.data.message;
         }*/
      });
    });
  }

  addMessage(campaignId: any, adminId: any) {
    this.data.campaign_id = campaignId;
    this.data.adminId = adminId;
    this.data.message=this.inputMsg;
    //console.log("Data",this.data);
    //return false;
    //chat_message
    this.chat.addChat(this.data).subscribe((result: any) => {
      //console.log("result:",result);
      if (result.status !== 200) {
        this.errorMsg = result.errors;
        this.router.navigate(['/chat/'+adminId+'/'+campaignId]);
      } else {
        this.infoMsg = result.data.message;
       // this.showThis = false;
        //card_btns
        this.router.navigate(['/chat/'+adminId+'/'+campaignId]);

      }
    });
  }

  onKey(event: any) { // without type info
    this.inputMsg=event.target.value;
  }
}
