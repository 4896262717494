import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient, private router: Router) { }

  getTerms(data: any) {
    return this.http.post(environment.apiURL + 'page/terms-of-service', data)
  }

  getPrivacy(data: any) {
    return this.http.post(environment.apiURL + 'page/privacy-policy', data)
  }

  contactUs(data:any){
    return this.http.post(environment.apiURL + 'page/contact-us-form-submit', data)
  }

  contentList(pageName:any) {
   // console.warn("content ser:",pageName);

    return this.http.post(environment.apiURL + 'page/'+pageName,{}).pipe(
      //catchError(this.errorHandle)
    );
  }

  errorHandle(error: HttpErrorResponse) {
    // console.log("=====", error.message);
     return throwError(error.message || 'Server Error!');
   }
}
