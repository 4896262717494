<div id="contact" class="form-1">
    <div class="container">
         <!-- end of row -->
        <div class="row">
            <div class="col-lg-6 offset-lg-3 text-start">
                <h2 class="text-center secondary-color">404</h2>
                <p class="text-center secondary-color">Page not found</p>
                <!-- Contact Form -->
                
                <!-- end of contact form -->
                <p class="text-center mt-5 secondary-color">We're sorry.The page you requested could not be found.</p>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>