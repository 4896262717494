<div class="main">
  <div class="container">
    <!-- end of row -->
    <div class="row">
      <div class="col-lg-8 offset-lg-2 text-start">
        <h2 class="mb-5">My Profile</h2>
        <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
        <!-- Contact Form -->
        <form [formGroup]="theForm" (ngSubmit)="updateProfile()">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngModel]="myself.full_name" formControlName="full_name" class="form-control-input"
                       placeholder="Full Name" required="">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngModel]="myself.email" formControlName="email" class="form-control-input"
                       placeholder="Email" required="">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                                <textarea [ngModel]="myself.address" formControlName="address"
                                          class="form-control-textarea" rows="1" placeholder="Address"
                                          ></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <select class="form-control-select" required="" formControlName="gender" [ngModel]="myself.gender">
                  <option class="select-option" value="" disabled="" selected="">Gender</option>
                  <option class="select-option" value="male">Male</option>
                  <option class="select-option" value="female">Female</option>
                  <option class="select-option" value="transgender">Transgender</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="date" [ngModel]="myself.date_of_birth" formControlName="date_of_birth" class="form-control-input"
                       placeholder="Date of birth">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngModel]="myself.insta_id" formControlName="insta_id" class="form-control-input"
                       placeholder="Instagram id">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngModel]="myself.tiktok_id" formControlName="tiktok_id" class="form-control-input"
                       placeholder="Tiktok id">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngModel]="myself.password" formControlName="password" class="form-control-input"
                       placeholder="Password">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngModel]="myself.password_confirmation" formControlName="password_confirmation" class="form-control-input"
                       placeholder="Confirm password">
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input type="file" accept="image/png, image/gif, image/jpeg" formControlName="profile_pic"  class="form-control form-control-lg" (change)="handleInputChange($event)">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" [ngModel]="myself.mobile" formControlName="mobile" class="form-control-input"
                       placeholder="Mobile no" required="">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="custom-checkbox"> 
                    <div >
                        <div *ngFor="let item of genreData">
                            <input  type="checkbox" (change)="getGenreId($event,item.id)" name="{{ item.id }}" value="{{ item.id }}" name="checkbox-checked" />
                            {{ item.name}} 
                        </div>                                
                    </div>
                </label>
              </div>
            </div>
          </div>

          <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <button type="submit" class="form-control-submit-button">Save</button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="text-end mt-2">

              </div>
            </div>
          </div>


        </form>
        <!-- end of contact form -->


      </div> <!-- end of col -->
    </div> <!-- end of row -->
  </div> <!-- end of container -->
</div>
