import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { TermsComponent } from './components/terms/terms.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CampaignComponent } from './components/campaign/campaign.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorComponent } from './components/widgets/error/error.component';
import { AuthGuard } from './guard/auth.guard';
import { RouterModule } from '@angular/router';
import { OrganizationDetailsComponent } from './components/organization-details/organization-details.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalPopupComponent } from './components/widgets/modal-popup/modal-popup.component';
import { CampaignDetailsComponent } from './components/campaign-details/campaign-details.component';
import { AlertModalComponent } from './components/widgets/alert-modal/alert-modal.component';
import { SuccessModalComponent } from './components/widgets/success-modal/success-modal.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { InfoComponent } from './components/widgets/info/info.component';
import { AcceptModalComponent } from './components/widgets/accept-modal/accept-modal.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { GetOtpComponent } from './components/get-otp/get-otp.component';
import { StatsComponent } from './components/stats/stats.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { GiftComponent } from './components/gift/gift.component';
import { EventComponent } from './components/event/event.component';
import { ChatComponent } from './components/chat/chat.component';
import { FullCalendarModule } from '@fullcalendar/angular';

  





@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    RegistrationComponent,
    LoginComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    TermsComponent,
    DashboardComponent,
    CampaignComponent,
    OrganizationComponent,
    ProfileComponent,
    CalendarComponent,
    ErrorComponent,
    OrganizationDetailsComponent,
    AboutComponent,
    ContactComponent,
    ModalPopupComponent,
    CampaignDetailsComponent,
    AlertModalComponent,
    SuccessModalComponent,
    ChangePasswordComponent,
    InfoComponent,
    AcceptModalComponent,
    VerifyOtpComponent,
    GetOtpComponent,
    StatsComponent,
    PrivacyComponent,
    ResetPasswordComponent,
    StatsComponent,
    GiftComponent,
    EventComponent,
    ChatComponent 
  ],
  imports: [
    BrowserModule,
    FullCalendarModule,
    AppRoutingModule,
    NgxPaginationModule,
    RouterModule.forRoot([

    ]),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule

  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
  //loggedIn:any=false;
  //ngOnInit():void{
  // this.loggedIn=this.userService.isLogin();
  // }
}
