import { Component } from '@angular/core';
import { CampaignService } from 'src/app/service/campaign.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';




@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent {
  //organizationList: any;
  errorMsg: any;
  infoMsg: any;
  showThis:any=true;
  campaignId: string | null = null;
  adminId: string | null = null;
  

  title = "pagination";
  page: number = 1;
  count: number = 0;
  tableSize: number = 4;
  tableSizes: any = [5, 10, 15, 20];

  //Define a property to store data
  campaignList: any;
  //public errorMsg: any;
  isError: boolean = false;
  pageId: any;
  cType: any;
  data:any={};

  constructor(
    private campaign: CampaignService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private commonService:CommonService,
    private campaignService:CampaignService,
    private router:Router
  ) { }

  campaignType: string | null = null;
  //campaignType=this.route.snapshot.paramMap.get('type');




  ngOnInit(): void {
    this.campaignDataList();
  }

  campaignDataList(): void {
    this.activatedRoute.paramMap.subscribe(res => {
      this.pageId = res.get("campaignType");
      if (this.pageId == '1') {
        this.cType = 'ACTIVE';
      }
      else if (this.pageId == '2') {
        this.cType = 'UPCOMING';
      }
      else if (this.pageId == '3') {
        this.cType = 'COMPLETED';
      }
      else {
        this.cType = 'INVITED';
      }

      
      this.campaign.campaignList(this.cType).subscribe((result: any) => {
        //this.campaignList = this.sanitizer.bypassSecurityTrustHtml(result.data.campaigns);  
        this.campaignList = result.data.campaigns;
        //console.warn("AAAA:",result.data.campaigns);
        if(result.status===200)
        {
          this.errorMsg = result.data.message;
        } else {
          this.errorMsg = result.data.message;
        }
      });
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.campaignDataList();
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.campaignDataList();
  }
  

  clickConfirm(campaignId:any) {
    if(confirm("Are you sure to accept this Campaign?")) {
      

      //alert(campaignId);
      this.data.campaign_id = campaignId;
      this.data.is_accepted = true;
  
      this.campaignService.acceptCampaign(this.data).subscribe((result: any) => {
        
        if (result.status !== 200) {
          this.errorMsg = result.errors;
          this.router.navigate(['/campaign/4']);
        } else {
          this.infoMsg = result.data.message;
          this.showThis=false;
          //card_btns
          this.router.navigate(['/campaign/2']);
         
        }
      });
    }
  }
  
  clickCancel(campaignId:any) {
    if(confirm("Are you sure to cancel this?")) {
      this.data.campaign_id = campaignId;
      this.data.is_accepted = false;
      this.data.reason_for_decline = 'I already have too much work.';
      this.data.decline_message = "I already have too much work.";

      this.campaignService.declineCampaign(this.data).subscribe((result: any) => {
        if (result.status !== 200) {
          this.errorMsg = result.errors;
          this.showThis = true;
          this.router.navigate(['/campaign/1']);
        } else {
          this.infoMsg = result.data.message;
          this.showThis = false;
          this.router.navigate(['/campaign/1']);
        }
      })
    }
  }

  viewInvoice(invoiceURL:any) {
    window.open(invoiceURL, "_blank");
    //if(confirm("Are you sure to cancel ")) {
      //console.log("Implement delete functionality here");
    //}
  }

  showChat(adminId:any,campaignId:any) {
    window.open('chat/'+adminId+'/'+campaignId, "_parent");

    this.campaignId = campaignId;
    
    this.adminId = adminId;;
    //this.is_accepted = true;
    //alert(adminId+'--'+campaignId);
    //window.open(invoiceURL, "_blank");
    //if(confirm("Are you sure to cancel ")) {
      //console.log("Implement delete functionality here");
    //}
  }
}
