<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                <h2>About Us</h2>
                <div class="sponsored">
                    <div class="card mb-3 col-lg-12" style="max-width:100%">
                        <div class="row no-gutters">
                            <div class="col-md-7">
                                <img src="assets/images/details.jpg" class="card-img" alt="...">
                            </div>
                            <div class="col-md-5">
                                <div class="card-body">
                                    <h4 class="card-title">{{ pagedate?pagedate.page.heading:'NA' }}</h4>
                                   
                                    <p class="card-text" [innerHTML]="pagedate?pagedate.page.content:'NA'">
                                       
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>