<nav id="navbarExample" class="navbar navbar-expand-lg fixed-top top-nav-collapse navbar-light " aria-label="Main navigation">
    <div class="container">

        <!-- Image Logo -->
        <a class="navbar-brand logo-image" routerLink="/dashboard"><img src="assets/images/logo.png" alt="alternative"></a>

        <!-- Text Logo - Use this if you don't have a graphic logo -->
        <!-- <a class="navbar-brand logo-text" href="index.html">Zinc</a> -->

        <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault" *ngIf="loggedIn">
            <ul class="navbar-nav ms-auto navbar-nav-scroll">
                <li class="nav-item">
                    <a routerLink="/about" routerLinkActive="active" class="nav-link" aria-current="page">About</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/dashboard" routerLinkActive="active" class="nav-link" aria-current="page">Dashboard</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown"
                        aria-expanded="false">Organizations</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                        <li><a routerLink="/organization/1" class="dropdown-item">INVITED</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/organization/2" class="dropdown-item">ACCEPTED</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/organization/3" class="dropdown-item">DENIED</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/organization/4" class="dropdown-item">EXPIRED</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown"
                        aria-expanded="false">Campaigns</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                        <li><a routerLink="/campaign/1" class="dropdown-item">Active</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/campaign/2" class="dropdown-item">Upcoming</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/campaign/3" class="dropdown-item">Completed</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/campaign/4" class="dropdown-item">Requested</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown"
                        aria-expanded="false">Gifts</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown01">
                        <li><a routerLink="/gift/1" class="dropdown-item">Active</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/gift/2" class="dropdown-item">Upcoming</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/gift/3" class="dropdown-item">Completed</a></li>
                        <li>
                            <div class="dropdown-divider"></div>
                        </li>
                        <li><a routerLink="/gift/4" class="dropdown-item">Requested</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown"
                        aria-expanded="false">Events</a>
                        <ul class="dropdown-menu" aria-labelledby="dropdown01">
                            <li><a routerLink="/event/1" class="dropdown-item">Active</a></li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                            <li><a routerLink="/event/2" class="dropdown-item">Upcoming</a></li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                            <li><a routerLink="/event/3" class="dropdown-item">Completed</a></li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                            <li><a routerLink="/event/4" class="dropdown-item">Requested</a></li>
                        </ul>
                </li>

                <!--<li class="nav-item dropdown">
                    <a routerLink="/calendar" class="nav-link">Calendar</a>
                </li>-->
                <li class="nav-item">
                    <a routerLink="/contact" routerLinkActive="active" class="nav-link" aria-current="page">Contact Us</a>
                </li>
            </ul>

            <div class="dropdown">
                <button class="btn btn-default dropdown-toggle" role="button" data-bs-toggle="dropdown">
                    <img src="assets/images/dummy-user.jpg" class="rounded-circle" width="40">
                </button>
                <ul class="dropdown-menu">
                    <li>
                        <a routerLink="/profile" class="dropdown-item" aria-current="page">Profile</a>
                    </li>
                    <!--<li>
                        <a routerLink="/organization" class="dropdown-item">Organizations</a>
                    </li>-->
                    <li>
                        <a routerLink="/change-password" class="dropdown-item" aria-current="page">Change Password</a>
                    </li>
                    <li>
                        <a style="cursor: pointer" class="dropdown-item" (click)="logout()">Logout</a>
                    </li>
                </ul>
            </div>

        </div> <!-- end of navbar-collapse -->
        <div class="nav-item" *ngIf="!loggedIn">
            <a class="btn-solid-sm" routerLink="/login">Login</a>
        </div>
    </div> <!-- end of container -->
</nav> <!-- end of navbar -->
