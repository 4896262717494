import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  //sendArr:any={};
  constructor(private http: HttpClient, private router: Router) { }

  organizationList(inviteType:any): Observable<any> {
    /*let data: any = {}
    data.generic_status = 'COMPLETED';
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    this.http.post("https://vava-backend.nettrackers.in/api/campaign/list-by-generic-status", data, { headers }).subscribe((result: any) => {
      console.warn(result.data.campaigns);
    })*/
    //let data: any = {}
    //data.status = 'INVITED';
    
    let data: any = {}
    data.status = inviteType;
    data.offset = 0;
    data.limit = 100;
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'organization/invites', data, { headers });
  }

  organizationDetails(id: any) {
    /*let data: any = {}
    data.generic_status = 'COMPLETED';
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`)
    this.http.post("https://vava-backend.nettrackers.in/api/campaign/list-by-generic-status", data, { headers }).subscribe((result: any) => {
      console.warn(result.data.campaigns);
    })*/
    let data: any = {}
    data.organization_id = id;
    //data.reason_for_decline='sss'
    //console.warn("send:",data);
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'organization/info', data, { headers });
  }

  acceptOrganization(sendArr: any) {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'organization/invite-response', sendArr, { headers });
  }

  declineOrganization(sendArr: any) {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'organization/invite-response', sendArr, { headers });
  }
}
