import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private router: Router) { }
  login(loginInfo: Array<string>) {
    /*return this.http.post(environment.apiURL + 'user/login', loginInfo).subscribe((result: any) => {
      if (result.status !== 200) {
        this.router.navigate(['/login']);
      } else {
        //console.warn(result.data.userInfo.full_name);
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("name", result.data.userInfo.full_name);
        this.router.navigate(['/dashboard']);
      }
    });*/

    return this.http.post(environment.apiURL + 'user/login', loginInfo)
  }

  logout() {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);

    this.http.get(environment.apiURL + 'user/logout', { headers }).subscribe((result: any) => {
      //console.warn(result);
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      this.router.navigate(['/login']);
    })
  }

  isLogin(): any {
    if (localStorage.getItem("token")) {
      return true;
    }
    else {
      localStorage.setItem('redirectMsg', 'You need to login to access this page.');
      this.router.navigate(['/login']);
    }
  }

  addUser(data: any) {
    return this.http.post(environment.apiURL + 'user/create', data)
  }

  getOtp(data: any) {
    return this.http.post(environment.apiURL + 'user/verify-email-send-otp', data)
  }

  verifyOtp(data: any) {
    return this.http.post(environment.apiURL + 'user/verify-email-verify-otp', data)
  }

  resetPasswordSendOtp(data: any) {
    return this.http.post(environment.apiURL + 'user/reset-password-send-otp', data)
  }

  resetPasswordVerifyOtp(data: any) {
    return this.http.post(environment.apiURL + 'user/reset-password-verify-otp', data)
  }

  getLoginUser(data: any) {
    let headers = new HttpHeaders().set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'user/myself', {}, { headers });
  }

  profileUpdate(data: any) {
    let headers = new HttpHeaders().set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'user/profile-update', data, { headers });
  }

}
