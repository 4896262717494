<div class="main">
    <div class="container">
         <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                <h2>Campaign Details</h2>
                <div class="sponsored">
                    <div class="card mb-3" style="max-width:100%">
                        <div class="row no-gutters">
                            <div class="col-md-7">
                                <img src="assets/images/details.jpg" class="card-img" alt="...">
                            </div>
                            <div class="col-md-5">
                                <div class="card-body">
                                    <h4 class="card-title">Life Style Sports</h4>
                                    <h5>Sponsored Strategy</h5>
                                    <p class="card-text">Life Style Sports is Ireland's largest sports  etailer, operating 46 outlets spread across..Life Style Sports is Ireland's largest sports  etailer, operating 46 outlets spread across..Life Style Sports is Ireland's largest sports  etailer, operating 46 outlets spread across..Life Sty</p>
                                    <h6>Status: <span>Active</span></h6>
                                    <div class="card_btns">
                                        <a href="javascript:void(0)" class="btn btn-outline-success">More</a>
                                        <a href="javascript:void(0)" class="btn btn-outline-danger">Decline</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                     
                    
                    
                    
                </div>
               </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>