import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  data: any = {};
  errorMsg: any;
  infoMsg: any;
  loginForm: any = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });
  constructor(private userService: UserService, private router: Router) { }
  ngOnInit(): void {
    // REDIRECT MSG
    if(localStorage.getItem('redirectMsg'))
    {
      this.infoMsg = localStorage.getItem('redirectMsg');
      localStorage.removeItem('redirectMsg');
    }
  }
  loginUser() {
    this.errorMsg = '';
    this.infoMsg = '';
    this.data.email = this.loginForm.value.email;
    this.data.password = this.loginForm.value.password;
    this.data.fcm_token = 'long_abcd_token';
    this.userService.login(this.data).subscribe((result: any) => {
      if (result.status !== 200) {
        this.errorMsg = result.errors[0];
        this.router.navigate(['/login']);
      } else {
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("name", result.data.userInfo.full_name);
        this.router.navigate(['/dashboard']);
      }
    });
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }
}
