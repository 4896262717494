import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient, private router: Router, private activeRoute: ActivatedRoute) { }

  eventList(eventType: any) {
    let data: any = {}
    data.generic_status = eventType;
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'event/list-by-generic-status', data, { headers }).pipe(
      //catchError(this.errorHandle)
    );
  }

  acceptEvent(data: any) {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'event/invite-response', data, { headers })
  }

  declineEvent(data: any) {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'event/invite-response', data, { headers })
  }
}
