import { Component } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  loggedIn: any = false;
  loggedUser:any='';
  constructor(private userService:UserService, private route: Router) { }

  ngOnInit(): void {
    this.route.events.subscribe((val: any) => {
     // console.warn('url name',val.url);
      if (localStorage.getItem("token")) {
        this.loggedUser=localStorage.getItem("name");
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
     // console.warn('logged user',this.loggedIn);
    })
  }

  logout() {
    this.userService.logout();
  }
}
