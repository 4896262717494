import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  errorMsg: any;
  infoMsg: any;
  data: any = {};
  registrationEmail: any;

  theForm: any = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(private router: Router, public userService: UserService) {}

  resetPasswordSendOtp(): void {
    this.data.email = this.theForm.value.email;

    this.userService.resetPasswordSendOtp(this.data).subscribe((result: any) => {
      if (result.errors.length > 0)
        this.errorMsg = result.errors[0];
      else {
        localStorage.setItem('registrationEmail', this.data.email);
        localStorage.setItem('redirectMsg', 'OTP sent to your email, please verify');
        this.router.navigate(['/reset-password']);
      }
    });
  }
}
