<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                <h2>Organization Details</h2>
                <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                <div class="sponsored">
                    <div class="card mb-3 col-lg-12" style="max-width:100%">
                        <div class="row no-gutters">
                            <div class="col-md-5">
                                <img src="{{ details.images?details.images[0].image_name:'NA' }}" class="card-img"   alt="a">
                            </div>
                            <div class="col-md-7">
                                <div class="card-body">
                                    <h4 class="card-title">{{ details.name }}</h4>
                                    <!--<h5>Sponsored Strategy</h5>-->
                                    <p class="card-text" [innerHTML]="details.description" >  </p>
                                    <h6>Managed By: <span>{{ details.organizationInfo?details.organizationInfo.name:'NA' }}({{ details.organizationInfo?details.organizationInfo.adminInfo.full_name:'NA' }})</span></h6>
                                    <h6>Price: <span>{{ details.organizationInfo?details.organizationInfo.adminInfo.full_name:'NA' }}</span></h6>
                                    <p class="card-text">Terms File: <span><a href="{{details.tos_file}}" target="_blank">View</a></span></p>
                                    <div class="card_btns">
                                        <a href="javascript:void(0)" class="btn btn-outline-success" (click)="clickConfirm()" >Accept</a> &nbsp;
                                        <a href="javascript:void(0)" class="btn btn-outline-danger" (click)="clickCancel()">Decline</a>
                                        
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>