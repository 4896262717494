<div class="main">
    <div class="container">
         <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                <h2>{{pageData.heading}}</h2>
                <div class="sponsored">
                    <div class="card mb-3" style="max-width:100%">
                        <div class="row no-gutters">
                            <div class="col-md-12">
                                <div class="card-body">
                                  <div [innerHTML]="pageData.content"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>
