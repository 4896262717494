import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CampaignDetailsComponent } from './components/campaign-details/campaign-details.component';
import { CampaignComponent } from './components/campaign/campaign.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ContactComponent } from './components/contact/contact.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ErrorComponent } from './components/error/error.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { OrganizationDetailsComponent } from './components/organization-details/organization-details.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { StatsComponent } from './components/stats/stats.component';
import { GetOtpComponent } from './components/get-otp/get-otp.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { TermsComponent } from './components/terms/terms.component';
import { AuthGuard } from './guard/auth.guard';
import { GiftComponent } from './components/gift/gift.component';
import { EventComponent } from './components/event/event.component';
import { ChatComponent } from './components/chat/chat.component';
import { PrivacyComponent } from './components/privacy/privacy.component';


const routes: Routes = [
  {
    component: LoginComponent,
    path: ''
  },
  {
    component: LoginComponent,
    path: 'login'
  },
  {
    component: RegistrationComponent,
    path: 'registration'
  },
  {
    component: GetOtpComponent,
    path: 'get-otp'
  },
  {
    component: VerifyOtpComponent,
    path: 'verify-otp'
  },
  {
    component: ForgotPasswordComponent,
    path: 'forgot-password'
  },
  {
    component: ResetPasswordComponent,
    path: 'reset-password'
  },
  {
    component: TermsComponent,
    path: 'terms'
  },
  {
    component: PrivacyComponent,
    path: 'privacy'
  },
  {
    component: DashboardComponent,
    path: 'dashboard',
    canActivate: [AuthGuard]
  },
  {
    component: AboutComponent,
    path: 'about',
    canActivate: [AuthGuard]
  },
  {
    component: ChangePasswordComponent,
    path: 'change-password',
    canActivate: [AuthGuard]
  },
  {
    component: ContactComponent,
    path: 'contact',
    canActivate: [AuthGuard]
  },
  {
    component: OrganizationComponent,
    path: 'organization/:inviteType',
    canActivate: [AuthGuard]
  },
  /*{
    component: OrganizationDetailsComponent,
    path: 'organization/details/:id',
    canActivate: [AuthGuard]
  },*/
  {
    component: CampaignComponent,
    path: 'campaign/:campaignType',
    canActivate: [AuthGuard]
  },
  {
    component: CampaignDetailsComponent,
    path: 'campaign/details/:id',
    canActivate: [AuthGuard]
  },
  {
    component: StatsComponent,
    path: 'stats/:campaignid',
    canActivate: [AuthGuard]
  },
  {
    component: GiftComponent,
    path: 'gift/:giftType',
    canActivate: [AuthGuard],
  },
  {
    component: EventComponent,
    path: 'event/:eventType',
    canActivate: [AuthGuard],
  },
  {
    component: ChatComponent,
    path: 'chat/:adminId/:campaignId',
    canActivate: [AuthGuard],
  },
  {
    component: ProfileComponent,
    path: 'profile',
    canActivate: [AuthGuard],
  },
  /*{
    //component:ChangePasswordComponent,
    //path: 'change-password',
    //canActivate: [AuthGuard]
  },*/
  
  {
    component: CalendarComponent,
    path: 'calendar',
    canActivate: [AuthGuard]
  },
  {
    component: ErrorComponent,
    path: '**'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
