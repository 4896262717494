import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';

import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class CampaignService {
   pageId:any;
  constructor(private http: HttpClient, private router: Router, private activeRoute:ActivatedRoute) {

    //
  }
  campaignList(campaignType:any) {
    /*this.activeRoute.paramMap.subscribe(res => {
       this.pageId = res.get("campaignType");
      // console.log("res:",res.get("campaignType"));
    });
    console.warn("campaignType:",this.pageId);*/
    let data: any = {}
    data.generic_status = campaignType;
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'campaign/list-by-generic-status', data, { headers }).pipe(
      //catchError(this.errorHandle)
    );
  }

  campaignDetails(campaignId:any) {
    /*this.activeRoute.paramMap.subscribe(res => {
       this.pageId = res.get("campaignType");
      // console.log("res:",res.get("campaignType"));
    });
    console.warn("campaignType:",this.pageId);*/
    let data: any = {}
    data.campaign_id = campaignId;
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'campaign/info', data, { headers }).pipe(
      //catchError(this.errorHandle)
    );
  }

  //acceptCampaign(data: any) {
    //return this.http.post(environment.apiURL + 'user/create', data).subscribe((result: any) => {
      //console.warn("result->",result);
      /*if (result.status !== 200) {
        this.router.navigate(['/login']);
      } else {
        localStorage.setItem("token", result.data.token);
        this.router.navigate(['/campaign/COMPLETED']);
      }*/
    //});
  //}

  acceptCampaign(data: any) {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'campaign/invite-response', data, { headers })
  }

  declineCampaign(data: any) {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'campaign/invite-response', data, { headers })
  }

  /*errorHandle(error: HttpErrorResponse) {
   // console.log("=====", error.message);
    return throwError(error.message || 'Server Error!');
  }*/
}
