import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { UserService } from 'src/app/service/user.service';
import { ContentService } from 'src/app/service/content.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  errorMsg: any;
  infoMsg: any;
  data: any = {};

  theForm: any = new FormGroup({
    subject: new FormControl(''),
    message: new FormControl(''),
  });

  constructor(
    private router: Router,
    public userService:UserService,
    public contentService:ContentService
  ) { }

  ngOnInit():void{
    // REDIRECT MSG
    if(localStorage.getItem('redirectMsg'))
    {
      this.infoMsg = localStorage.getItem('redirectMsg');
      localStorage.removeItem('redirectMsg');
    }
  }

  contactFormSubmit():void{
    this.errorMsg = '';
    this.infoMsg = '';

    this.data.subject = this.theForm.value.subject;
    this.data.message = this.theForm.value.message;

    this.contentService.contactUs(this.data).subscribe((result: any)=> {
      if (result.errors.length > 0)
        this.errorMsg = result.errors[0];
      else {
        this.infoMsg = result.data.message;
        this.data.subject = '';
        this.data.message = '';
      }
    });

  }

}
