<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-6 offset-lg-3 text-start">

                <div class="card">
                    <div class="card-header">
                        Calendar
                    </div>
                    <div class="card-body">
                        <full-calendar [options]="calendarOptions"></full-calendar>
                    </div>
                </div>



            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>