<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">

            <div class="col-lg-12">
                <h2 class="mb-4 ">Message </h2>
                 <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                    <div class="card mb-4 mw-100">
                        <div class="normal_chat">
                            <div class="right">
                                <div class="top"><span>To: <span class="name">Administrator</span></span></div>
                                <div class="chat" id="chat" data-chat="person1">
                                    <!--<div class="conversation-start">
                                        <span>Today, 6:48 AM</span>
                                    </div>-->
                                    <div *ngFor="let item of chatList" class="clearfix">
                                        <div class="bubble you" *ngIf="item.self" >
                                            {{item.message}}
                                        </div>
                                        <div class="bubble me" *ngIf="!item.self" >
                                            {{item.message}}
                                        </div>
                                    </div>
                                    
                                    
                                    <!--<div class="bubble me">
                                        Chat is loading..
                                    </div>-->
                                    
                                </div>
                                
                                <div class="write upload_send_icon">
                                    <input type="text" formControlName="chat_message" (keyup)="onKey($event)" id="chat_message" />
                                    <div class="write-rightblock">
                                        <div class="file-uploadbox"> 
                                            <label for="sortpicture">
                                                <img src="assets/images/attachment.png">
                                              </label>                                            
                                            <input type="file" name="sortpic" id="sortpicture" class="write-link file-upload">                                           
                                        </div>
                                        <a href="javascript:;" class="write-link send" (click)="addMessage(campaignId,adminId)" ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>