<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">

            <div class="col-lg-12">
                <h2>Sponsored Campaigns </h2>
                <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                <div class="sponsored">
                    <div class="card mb-3" *ngFor="let item of campaignList">
                        <div class="row no-gutters">
                            <div class="col-md-4 list_thumbnail">
                                <img src={{item.campaignInfo.images[0].image_name}} class="card-img" alt="...">
                            </div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    <h4 class="card-title">{{item.campaignInfo.name}}</h4>
                                    <h5>Start On: {{item.campaignInfo.start_date | date:'longDate'}}</h5>
                                    <div class="card-text" [innerHTML]="item.campaignInfo.description"></div>
                                    <h6>Price: <span>$ {{item.budget}} </span></h6>
                                    <div class="card_btns" *ngIf="item.status=='INVITED'">
                                        <a href="javascript:void(0)" class="btn btn-outline-success"
                                            (click)="clickConfirm(item.campaignInfo.id)">Accept</a> &nbsp;
                                        <a href="javascript:void(0)" class="btn btn-outline-danger"
                                            (click)="clickCancel(item.campaignInfo.id)">Decline</a>
                                    </div>

                                    <div class="card_btns"
                                        *ngIf="(item.status!=='INVITED' && item.status!=='REJECTED')">
                                        <a routerLink="/stats/{{item.campaignInfo.id}}"
                                            class="btn btn-outline-success">Stats</a> &nbsp;
                                        <a href="javascript:void(0)" (click)="viewInvoice(item.invoice)"
                                            class="btn btn-outline-success">Invoice</a> &nbsp;
                                        <a href="javascript:void(0)"
                                            (click)="showChat(item.campaignInfo.organizationInfo.admin_id,item.campaign_id)"
                                            class="btn btn-outline-success">Chat</a>
                                    </div>

                                    <div class="card_btns" *ngIf="(item.status==='REJECTED')">
                                        <a href="javascript:void(0)" class="btn btn-danger btn-lg">REJECTED</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>