<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-6 offset-lg-3 text-start">
                <h2 class="mb-5">Contact Us</h2>
              <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                <!-- Contact Form -->
              <form [formGroup]="theForm" (ngSubmit)="contactFormSubmit()">
                    <div class="form-group">
                        <input type="text" [ngModel]="data.subject" formControlName="subject" class="form-control-input" placeholder="Subject" required="">
                    </div>

                    <div class="form-group">
                        <textarea [ngModel]="data.message" formControlName="message" class="form-control-textarea" rows="1" placeholder="Your Message" required=""></textarea>
                    </div>

                    <div class="form-group">
                      <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                    </div>

                    <div class="form-group">
                        <button type="submit" class="form-control-submit-button">Save</button>
                    </div>
                </form>
               </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>
