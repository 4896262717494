import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from 'src/app/service/campaign.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.css']
})
export class CampaignDetailsComponent {
  campaignId: string | null = null;
  details:any={};
  data:any={};
  errorMsg: any;
  showThis:any=true;
constructor(private campaignService:CampaignService,private route: ActivatedRoute,private router:Router){}

ngOnInit(): void {
  this.campaignId = this.route.snapshot.paramMap.get("id");
  //console.warn("campaignId",this.campaignId);
  this.campaignService.campaignDetails(this.campaignId).subscribe((result: any) => {
    //this.campaignList = this.sanitizer.bypassSecurityTrustHtml(result.data.campaigns);  
    this.details = result.data.campaignInfo;
    console.warn("details:", this.details);
  })
}

clickConfirm() {
  if(confirm("Are you sure to accept this Campaign.")) {
    //this.campaignId = this.route.snapshot.paramMap.get("id");
    //alert(this.campaignId);
   // return false;
    this.data.campaign_id = this.campaignId;
    this.data.is_accepted = true;
    //this.data.fcm_token = 'long_abcd_token';

    this.campaignService.acceptCampaign(this.data).subscribe((result: any) => {
      //console.log("result:",result.data.message,result.status);
     // return false;
      if (result.status !== 200) {
        this.showThis=false;
        this.errorMsg = result.data.message;
        this.router.navigate(['/campaign/'+this.campaignId]);
      } else {
        this.errorMsg = result.data.message;
        this.router.navigate(['/campaign/details/'+this.campaignId]);
        //localStorage.setItem("token", result.data.token);
       // localStorage.setItem("name", result.data.userInfo.full_name);
       // this.router.navigate(['/dashboard']);
      }
    });
  }
}

clickCancel() {
  if(confirm("Are you sure to cancel ")) {
    //console.log("Implement delete functionality here");
  }
}
}
