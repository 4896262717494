<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                <h2>Organization Details</h2>
                <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                <div class="sponsored">
                    <div class="card mb-3 col-lg-12" style="max-width:100%">
                        <app-success-modal [successMsg]="successMsg" *ngIf="successMsg"></app-success-modal>
                        <div class="row no-gutters" *ngIf="details">
                            <div class="col-md-5">
                                <img src="{{ details?details.logo:'NA' }}" class="card-img"   alt="{{ details?details.name:'NA' }}">
                            </div>
                            <div class="col-md-7">
                                <div class="card-body">
                                    <h4 class="card-title">{{ details?details.name:'NA' }}</h4>
                                    <!--<h5>Sponsored Strategy</h5>-->
                                    <p class="card-text">{{ details?details.description:'NA' }}
                                    </p>
                                    <h6>Managed By: <span>{{ details?details.adminInfo.full_name:'NA' }}</span></h6>
                                    <div class="card_btns">
                                        <a  class="btn btn-outline-success">Accept</a> &nbsp;
                                        <a href="javascript:void(0)" class="btn btn-outline-danger">Decline</a>
                                        
                                       <!--<app-modal-popup></app-modal-popup>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>