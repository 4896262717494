import { Component,OnInit,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {
  //@Input() type:any;
  //@Output() onClose=new EventEmitter<any>();

  constructor(){}
  close(){
   // this.onClose.emit();
  }
 //@Output() onClose=new EventEmitter<any>():
}
