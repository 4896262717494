<div id="contact" class="form-1">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-6 offset-lg-3 text-start">
                <h2>Verify OTP</h2>
              <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                <p class="secondary-color">Verify your email by OTP</p>
                <!-- Contact Form -->
                <form [formGroup]="theForm" (ngSubmit)="confirmOtp()">

                    <div class="form-group">
                        <input type="email" [ngModel]="registrationEmail" class="form-control-input" placeholder="Email" formControlName="email">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control-input" placeholder="OTP" formControlName="otp">
                    </div>

                  <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <button type="submit"
                                    class="form-control-submit-button">Submit</button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="text-end mt-2">
                                <a routerLink="/get-otp">Get a new OTP</a>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- end of contact form -->
                <p class="text-center mt-5">Don’t have an account? <a routerLink="/registration">Create new now!</a></p>
                <p class="text-center">By signing up, you are agree with our <a routerLink="/terms">Terms &amp;
                        Conditions</a></p>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>
