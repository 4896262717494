import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GiftService {

  constructor(private http: HttpClient, private router: Router, private activeRoute:ActivatedRoute) { }

  giftList(giftType:any) {
    /*this.activeRoute.paramMap.subscribe(res => {
       this.pageId = res.get("campaignType");
      // console.log("res:",res.get("campaignType"));
    });
    console.warn("campaignType:",this.pageId);*/
    let data: any = {}
    data.generic_status = giftType;
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'gift/list-by-generic-status', data, { headers }).pipe(
      //catchError(this.errorHandle)
    );
  }

  acceptGift(data: any) {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'gift/invite-response', data, { headers })
  }

  declineGift(data: any) {
    let headers = new HttpHeaders()
      .set("Authorization", `Bearer ${localStorage.getItem('token')}`);
    return this.http.post(environment.apiURL + 'gift/invite-response', data, { headers })
  }
}
