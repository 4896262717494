<div class="main">
    <div class="container">
        <!-- end of row -->
        <div class="row">
            <div class="col-lg-12">
                <h2 class="mb-5">Upload Stats</h2>
                <app-alert-modal [errorMsg]="errorMsg" *ngIf="errorMsg"></app-alert-modal>
                <app-info [infoMsg]="infoMsg" *ngIf="infoMsg"></app-info>
                <div class="sponsored">
                    <div class="card mb-3 col-lg-12" style="max-width:100%">
                        <div class="col-lg-6 offset-lg-3 text-start mt-3">
                        <form>
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="file" accept=
                                        "application/msword, text/plain, application/pdf"  class="form-control form-control-lg" (change)="handleInputChange($event)">
                                    </div>
                                </div>
                            </div>                            
                            <div class="form-group">
                                <button type="submit" (click)="onUpload()" class="form-control-submit-button">Upload</button>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of container -->
</div>