import { Component } from '@angular/core';
import { OrganizationService } from 'src/app/service/organization.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent {
  organizationList: any;
  title = "pagination";
  page: number = 1;
  count: number = 0;
  tableSize: number = 2;
  tableSizes: any = [5, 10, 15, 20];
  data: any = {};
  errorMsg: any;
  infoMsg: any;
  showThis: any = true;
  pageId: any;
  cType: any;
  orgList: any;

  constructor(
    private organization: OrganizationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }


  ngOnInit(): void {
    this.orgDataList();
  }

  /* orgDataList(): void {
     this.organization.organizationList().subscribe((result: any) => {
       //this.campaignList = this.sanitizer.bypassSecurityTrustHtml(result.data.campaigns);  
       this.organizationList = result.data.invites;
       console.warn(this.organizationList);
     })
   }*/

  orgDataList(): void {
    this.activatedRoute.paramMap.subscribe(res => {
      this.pageId = res.get("inviteType");
      if (this.pageId == '1') {
        this.cType = 'INVITED';
      }
      else if (this.pageId == '2') {
        this.cType = 'ACCEPTED';
      }
      else if (this.pageId == '3') {
        this.cType = 'DENIED';
      }
      else {
        this.cType = 'EXPIRED';
      }


      this.organization.organizationList(this.cType).subscribe((result: any) => {
        //this.campaignList = this.sanitizer.bypassSecurityTrustHtml(result.data.campaigns);  
        this.organizationList = result.data.invites;
        //console.warn("AAAA:",this.organizationList);
        if (result.status === 200) {
          this.errorMsg = result.data.message;
        } else {
          this.errorMsg = result.data.message;
        }
      });
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.orgDataList();
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.orgDataList();
  }

  clickConfirm(organizationId: any) {
    if (confirm("Are you sure to accept this invite?")) {
      this.data.organization_id = organizationId;
      this.data.is_accepted = true;
      this.organization.acceptOrganization(this.data).subscribe((result: any) => {
        if (result.status !== 200) {
          this.errorMsg = result.errors;
          this.showThis = true;
          this.router.navigate(['/organization/2']);
        } else {
          this.infoMsg = result.data.message;
          this.showThis = false;
          this.router.navigate(['/organization/2']);

        }
      });
    }
  }

  clickCancel(organizationId: any) {
    if (confirm("Are you sure to cancel this invite?")) {
      this.data.organization_id = organizationId;
      this.data.is_accepted = false;
      this.data.reason_for_decline = 'I already have too much work.';
      this.data.decline_message = "I already have too much work.";

      this.organization.declineOrganization(this.data).subscribe((result: any) => {
        if (result.status !== 200) {
          this.errorMsg = result.errors;
          this.showThis = true;
          this.router.navigate(['/organization/1']);
        } else {
          this.infoMsg = result.data.message;
          this.showThis = false;
          this.router.navigate(['/organization/3']);
        }
      });
    }
  }
}
