import { Component } from '@angular/core';
import { GiftService } from 'src/app/service/gift.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.css']
})
export class GiftComponent {
  //organizationList: any;
  errorMsg: any;
  infoMsg: any;
  showThis: any = true;
  giftId: string | null = null;

  title = "pagination";
  page: number = 1;
  count: number = 0;
  tableSize: number = 4;
  tableSizes: any = [5, 10, 15, 20];

  //Define a property to store data
  giftList: any;
  //public errorMsg: any;
  isError: boolean = false;
  pageId: any;
  cType: any;
  data: any = {};

  constructor(
    private gift: GiftService,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private giftService:GiftService,
    private router: Router
  ) { }

  giftType: string | null = null;
  //giftType=this.route.snapshot.paramMap.get('type');




  ngOnInit(): void {
    this.giftDataList();
  }

  giftDataList(): void {
    this.activatedRoute.paramMap.subscribe(res => {
      this.pageId = res.get("giftType");
      if (this.pageId == '1') {
        this.cType = 'ACTIVE';
      }
      else if (this.pageId == '2') {
        this.cType = 'UPCOMING';
      }
      else if (this.pageId == '3') {
        this.cType = 'COMPLETED';
      }
      else {
        this.cType = 'INVITED';
      }


      this.gift.giftList(this.cType).subscribe((result: any) => {
        this.giftList = result.data.gifts;
        console.warn("AAAA:",result.data.gifts);
        if (result.status === 200) {
          this.errorMsg = result.data.message;
        } else {
          this.errorMsg = result.data.message;
        }
      });
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.giftDataList();
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.giftDataList();
  }


  clickConfirm(giftId: any) {
    if (confirm("Are you sure to accept this Gift?")) {
      this.data.gift_id = giftId;
      this.data.is_accepted = true;

      this.giftService.acceptGift(this.data).subscribe((result: any) => {
        if (result.status !== 200) {
          this.errorMsg = result.errors;
          this.router.navigate(['/gift/4']);
        } else {
          this.infoMsg = result.data.message;
          this.showThis = false;
          //card_btns
          this.router.navigate(['/gift/2']);

        }
      });
    }
  }

  clickCancel(giftId: any) {
    if (confirm("Are you sure to cancel this Gift?")) {
      this.data.gift_id = giftId;
      this.data.is_accepted = false;
      this.data.reason_for_decline = 'I already have too much work.';
      this.data.decline_message = "I already have too much work.";

      this.giftService.declineGift(this.data).subscribe((result: any) => {
        if (result.status !== 200) {
          this.errorMsg = result.errors;
          this.showThis = true;
          this.router.navigate(['/gift/1']);
        } else {
          this.infoMsg = result.data.message;
          this.showThis = false;
          this.router.navigate(['/gift/1']);
        }
      });
    }
  }
}
