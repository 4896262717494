import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  loggedUser: any = '';
  constructor(private route: Router) { }
  ngOnInit(): void {
    this.route.events.subscribe((val: any) => {
      //console.warn('logged',val.url);
      if (localStorage.getItem("token")) {
        this.loggedUser = localStorage.getItem("name");

      } else {
        this.loggedUser = 'NA';
      }
    })
  }
}
