import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  displayStrDate(numDate: any) {
    const [dateComponents, timeComponents] = numDate.split(' ');

    //console.log(dateComponents); // 👉️ "07/21/2024"
    //console.log(timeComponents); // 👉️ "04:24:37"

    const [year, month, day] = dateComponents.split('-');
    const [hours, minutes, seconds] = timeComponents.split(':');

    //const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
    const date = new Date(+year, +month - 1);

    // 👇️ Sun Jul 21 2024 04:24:37
    //console.log(date);
    console.warn("numDate", date);
    return date;

  }
}
